import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from 'src/config-global';
import CompactLayout from 'src/layouts/compact';
import { authRoutes } from './auth';
import { sportsRoutes } from './sports';
import { casinoRoutes } from './casino';
import { dashboardRoutes } from './dashboard';
import { vendorRoutes } from './vendor';

// ----------------------------------------------------------------------
const Page404 = lazy(() => import('src/pages/404'));

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    // // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Sport routes
    ...sportsRoutes,

    // Casino routes
    ...casinoRoutes,

    // Vendor routes
    ...vendorRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    // ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '404',
      element: (
        <CompactLayout>
          <Page404 />
        </CompactLayout>
      ),
    },
  ]);
}
