import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField';
import PhoneNumber from './PhoneNumber';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};
export default function RHFPhoneField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneNumber
          {...field}
          fullWidth
          variant="outlined"
          defaultCountry="ht"
          onlyCountries={['ht', 'us', 'cl', 'br', 'do']}
          placeholder="+509 1234-5678"
          error={!!error}
          helperText={error ? error.message : helperText}
          {...other}
        />
      )}
    />
  );
}
