import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslate } from 'src/locales';
import { Typography, Box, IconButton } from '@mui/material';
import QRCode from 'react-qr-code';
import Iconify from 'src/components/iconify';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  betcodeDt: any;
};

export default function ShareBetCode({ open, onClose, betcodeDt }: Props) {
  const { t } = useTranslate();
  const codeUrl = `${window.location.origin}/#${paths.betcode.view(betcodeDt?.ref_code)}`;
  const shareText = t('betslip.check_out_this_betcode', { ref_code: betcodeDt?.ref_code });
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    codeUrl
  )}&quote=${shareText}%20${encodeURIComponent(codeUrl)}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    shareText
  )}%20${encodeURIComponent(codeUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    shareText
  )}%20${encodeURIComponent(codeUrl)}`;
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <Box display="flex" justifyContent="flex-end" p={0.5}>
        <IconButton onClick={onClose}>
          <Iconify icon="solar:close-circle-bold-duotone" />
        </IconButton>
      </Box>

      <Box textAlign="center" mb={2}>
        <Typography variant="h6" fontWeight="bold">
          {t('betcode')}
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          N°{betcodeDt?.ref_code}
        </Typography>
      </Box>

      <DialogContent dividers>
        <Box display="flex" flexDirection="column" alignItems="center">
          <QRCode size={180} value={codeUrl} />

          <Box mt={2} display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                mr: 1,
                width: 190,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {codeUrl}
            </Typography>
            <IconButton
              onClick={() => {
                enqueueSnackbar('Copied!');
                copy(codeUrl);
              }}
              size="small"
            >
              <Iconify icon="ph:link-bold" />
            </IconButton>
          </Box>

          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton
              color="primary"
              component="a"
              href={facebookShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconify width={32} icon="logos:facebook" />
            </IconButton>
            <IconButton
              color="primary"
              component="a"
              href={whatsappShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconify width={32} icon="ic:baseline-whatsapp" />
            </IconButton>
            <IconButton
              color="primary"
              component="a"
              href={twitterShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconify width={32} icon="logos:twitter" />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
