import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { useSelector } from 'src/redux/store';
import { selectBetSlipBets } from 'src/redux/slices/betslip';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

import Main from './main';
import Header from './header';
import Footer from './footer';
import NavMini from './nav-mini';
import NavSport from './nav-sport';
import NavVertical from './nav-vertical';
import BetslipModal from './bet-slip-modal';
import SportModal from './sport-modal';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const { t } = useTranslate();

  const theme = useTheme();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();
  const active_betslip = useBoolean();
  const active_choose_sport = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;
  const renderNavSport = <NavSport />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;
  const BetSlipModal = (
    <BetslipModal open={active_betslip.value} handleClose={active_betslip.onFalse} />
  );

  const ChooseSportModal = (
    <SportModal
      open={active_choose_sport.value}
      handleClose={active_choose_sport.onFalse}
      handleOpenNav={nav.onTrue}
    />
  );

  const betSlipBets = useSelector(selectBetSlipBets);

  const renderBottomNav = (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: 'flex', lg: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
        width: 1,
      }}
    >
      <BottomNavigation
        showLabels
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
          height: '70px',
          backgroundColor: theme.palette.primary.main,
          width: 1,
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <BottomNavigationAction
          label={t('layout.sport')}
          onClick={active_choose_sport.onTrue}
          icon={<Iconify icon="fluent:sport-16-filled" />}
          sx={{ color: '#fff', minWidth: '64px' }}
        />
        <BottomNavigationAction
          label={t('layout.casino')}
          component={Link}
          to={paths.casino.root}
          icon={<Iconify icon="cil:casino" />}
          sx={{ color: '#fff', minWidth: '64px' }}
        />
        <BottomNavigationAction
          label={t('layout.my_bets')}
          component={Link}
          to={paths.dashboard.user.account_menu('ticket')}
          icon={<Iconify icon="solar:ticket-bold-duotone" />}
          sx={{ color: '#fff', minWidth: '64px' }}
        />
        <BottomNavigationAction
          label={t('layout.my_account')}
          component={Link}
          to={paths.dashboard.user.account_menu('transaction')}
          icon={<Iconify icon="healthicons:money-bag" />}
          sx={{ color: '#fff', minWidth: '64px' }}
        />
      </BottomNavigation>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: 'absolute',
          top: '-30px',
          backgroundColor: theme.palette.primary.dark,
          '&:hover': {
            backgroundColor: theme.palette.primary.darker,
          },
        }}
        onClick={active_betslip.onTrue}
      >
        <Badge badgeContent={betSlipBets?.length} color="secondary">
          <Iconify icon="fa:shopping-basket" />
        </Badge>
      </Fab>
    </Box>
  );

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp && renderNavSport}
          {!lgUp && renderNavVertical}
          {!lgUp && BetSlipModal}
          {!lgUp && ChooseSportModal}

          <Main>{children}</Main>
        </Box>
        <Footer />
        {renderBottomNav}
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}
          {!lgUp && BetSlipModal}
          {!lgUp && ChooseSportModal}

          <Main>{children}</Main>
        </Box>
        <Footer />
        {renderBottomNav}
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}
        {BetSlipModal}
        {ChooseSportModal}

        <Main>{children}</Main>
      </Box>
      <Footer />
      {renderBottomNav}
    </>
  );
}
