import orderBy from 'lodash/orderBy';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import { useSelector } from 'src/redux/store';

import Iconify from 'src/components/iconify';
import Image from 'src/components/image/image';

// ----------------------------------------------------------------------

type LeagueProps = {
  id: number;
  logo: string;
  api_id: number;
  name: string;
  type: string;
  seasons: any[];
  sport: number;
  country: string | null;
};

interface Props extends CardProps {
  title?: string;
  load_more_txt?: string;
  subheader?: string;
}

export default function AppTopCompetition({ title, load_more_txt, subheader, ...other }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const { leagueId } = useParams<{ leagueId: string }>();
  const { leagues } = useSelector((state) => state.generale);

  const [visibleCount, setVisibleCount] = useState(8);

  const handleCompClick = (league: any) => {
    navigate(`/sports/${league.sport}/-1/${league.id}`);
  };

  const filteredLeagues = leagues.filter(
    (league: { country: null; is_top: null }) => league.country === null || league.is_top === true
  );

  const topLeagues = orderBy(filteredLeagues).slice(0, visibleCount);

  // Load more function
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  return (
    <>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{
          style: {
            color: PRIMARY_MAIN,
            fontSize: 14,
            fontWeight: '700',
            textTransform: 'uppercase',
          },
        }}
      />

      <Stack spacing={1.5} sx={{ px: 2, pt: 2 }}>
        {topLeagues.map((league, index) => (
          <LeagueItem
            key={league.id}
            league={league}
            index={index}
            selectedComp={parseInt(leagueId ?? '-1', 10)}
            onClick={() => handleCompClick(league)}
          />
        ))}
        {visibleCount < filteredLeagues.length && (
          <Button
            onClick={loadMore}
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {load_more_txt}
          </Button>
        )}
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

type LeagueItemProps = {
  league: LeagueProps;
  index: number;
  selectedComp: number;
  onClick: () => void;
};

const ItemCompetition = styled(Stack)<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '49px',
  minHeight: '34px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: active ? theme.palette.primary.main : '#E9EDF0',
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.light : theme.palette.primary.lighter,
  },
  cursor: 'pointer',
}));

function LeagueItem({ league, index, selectedComp, onClick }: LeagueItemProps) {
  return (
    <ItemCompetition
      active={selectedComp === league.id}
      direction="row"
      spacing={1}
      sx={{ p: 1 }}
      onClick={onClick}
    >
      <Image src={league.logo} sx={{ width: 24, height: 24, mr: 1 }} />
      <Box>
        <Typography variant="subtitle2">{league.name}</Typography>
      </Box>
    </ItemCompetition>
  );
}
