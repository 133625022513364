import { useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';
import { IS_VENDOR_PANEL } from 'src/config-global';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SportGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();
  const { authenticated, user } = useAuthContext();

  const check = useCallback(() => {
    if (IS_VENDOR_PANEL) {
      router.replace(paths.vendor.root);
    }
    if (authenticated && !user?.is_verified) {
      router.replace(paths.auth.jwt.verify);
    }
  }, [authenticated, router, user?.is_verified]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
