// slices/match.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { AppThunk } from '../store.types';

interface MatchState {
  isLoading: boolean;
  error: string | null;
  wallet: any | null;
  transactions: any[];
}

const initialState: MatchState = {
  isLoading: false,
  error: null,
  wallet: null,
  transactions: [],
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getWalletSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.wallet = action.payload;
    },
    getTransactionsSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.transactions = action.payload;
    },
  },
});

export const { startLoading, hasError, getWalletSuccess, getTransactionsSuccess } = slice.actions;

export default slice.reducer;

export const getWallet = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/api/v1/client/wallet/ballance`);
    dispatch(getWalletSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getTransactions = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/api/v1/client/wallet/transactions`);
    dispatch(getTransactionsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const postDeposit = async (data: any) => {
  const response = await axios.post(`/api/v1/client/wallet/deposit`, data);
  return response.data;
};

export const postConfirmDeposit = async (data: any) => {
  const response = await axios.post(`/api/v1/client/wallet/deposit/comfirmation`, data);
  return response.data;
};

export const postWithdrawal = async (data: any) => {
  const response = await axios.post(`/api/v1/client/wallet/withdrawal`, data);
  return response.data;
};
