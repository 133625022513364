import { combineReducers } from 'redux';

import matchReducer from './slices/match';
import walletReducer from './slices/wallet';
import ticketReducer from './slices/ticket';
import vendorReducer from './slices/vendor';
import counterReducer from './slices/counter';
import betslipReducer from './slices/betslip';
import generaleReducer from './slices/generale';

// ----------------------------------------------------------------------

export const rootReducer = combineReducers({
  counter: counterReducer,
  betslip: betslipReducer,
  generale: generaleReducer,
  wallet: walletReducer,
  ticket: ticketReducer,
  vendor: vendorReducer,
  match: matchReducer,
});
