import { memo, useState, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ListSubheader from '@mui/material/ListSubheader';

import Iconify from 'src/components/iconify';
import { NavProps, NavGroupProps } from 'src/components/nav-section/types';

import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionVertical({
  data,
  slotProps,
  load_more_txt,
  ...other
}: NavProps & { load_more_txt?: string }) {
  return (
    <Stack component="nav" id="nav-section-vertical" {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          slotProps={slotProps}
          load_more_txt={load_more_txt}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

function Group({
  subheader,
  items,
  slotProps,
  load_more_txt,
}: NavGroupProps & { load_more_txt?: string }) {
  const [open, setOpen] = useState(true);
  const [visibleItems, setVisibleItems] = useState(20);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + 20);
  };

  const renderContent = items
    .slice(0, visibleItems)
    .map((list) => <NavList key={list.title} data={list} depth={1} slotProps={slotProps} />);

  return (
    <Stack sx={{ px: 2 }}>
      {subheader ? (
        <>
          <ListSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            sx={{
              fontSize: 14,
              cursor: 'pointer',
              textTransform: 'uppercase',
              fontWeight: '700',
              display: 'inline-flex',
              color: (theme) => theme.palette.primary.main,
              p: (theme) => theme.spacing(1, 1, 0, 1.5),
              transition: (theme) =>
                theme.transitions.create(['color'], {
                  duration: theme.transitions.duration.shortest,
                }),
              ...slotProps?.subheader,
            }}
          >
            {subheader}
          </ListSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
          {visibleItems < items.length && (
            <Button
              onClick={handleLoadMore}
              variant="contained"
              color="primary"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{ my: 1 }}
            >
              {load_more_txt}
            </Button>
          )}
        </>
      ) : (
        renderContent
      )}
    </Stack>
  );
}
