import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslate } from 'src/locales';
import { useDispatch, useSelector } from 'src/redux/store';
import { getGames, getGameSuccess } from 'src/redux/slices/generale';

import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
}

export default function AppGames({ title, subheader, ...other }: Props) {
  const theme = useTheme();
  const { games, game_detail } = useSelector((state) => state.generale);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const handlePlay = (game: any) => {
    navigate(paths.casino.game_view(game?.id));
  };

  useEffect(() => {
    dispatch(getGames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{
          style: {
            color: PRIMARY_MAIN,
            fontSize: 14,
            fontWeight: '700',
            textTransform: 'uppercase',
          },
        }}
      />

      <Stack spacing={1.5} sx={{ p: 2 }}>
        {games?.map((game: any) => (
          <GameItem
            key={game.ref_code}
            game={game}
            selectedComp={game.ref_code === game_detail?.ref_code}
            onClick={() => handlePlay(game)}
          />
        ))}
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

type GameItemProps = {
  game: any;
  selectedComp: boolean;
  onClick: () => void;
};

const ItemGame = styled(Stack)<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '49px',
  height: '34px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: active ? theme.palette.primary.main : '#E9EDF0',
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.light : theme.palette.primary.lighter,
  },
  cursor: 'pointer',
}));

function GameItem({ game, selectedComp, onClick }: GameItemProps) {
  const { t } = useTranslate();
  return (
    <ItemGame active={selectedComp} direction="row" spacing={1} sx={{ p: 3 }} onClick={onClick}>
      <Iconify icon={game.icon} width={20} height={20} />
      <Box>
        <Typography variant="subtitle2">{t(`game.${game.name}`)}</Typography>
      </Box>
    </ItemGame>
  );
}
