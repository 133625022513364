import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslate } from 'src/locales';

import FormProvider, { RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCreate: (address: any) => void;
};

export default function DepositForm({ open, onClose, onCreate }: Props) {
  const { t } = useTranslate();
  const DepositSchema = Yup.object().shape({
    paymentMethod: Yup.string().required(t('wallet.requiredField')),
    amount: Yup.number().required(t('wallet.requiredField')),
  });

  const defaultValues = {
    paymentMethod: 'Moncash',
    amount: 10,
  };

  const methods = useForm({
    resolver: yupResolver(DepositSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      onCreate(data);
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('wallet.deposit')}</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <RHFRadioGroup
              row
              name="paymentMethod"
              options={[
                { label: 'Moncash', value: 'Moncash' },
                // { label: 'Natcash', value: 'Natcash' },
              ]}
            />

            <RHFTextField name="amount" label={t('wallet.amount')} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('wallet.cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('wallet.deposit')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
