import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

interface BetSlip {
  bet_type: 'SIMPLE' | 'MULTIPLE';
  bets: any[];
  total_stake: number;
}

interface MatchState {
  betslip: BetSlip;
}

const initialState: MatchState = {
  betslip: {
    bet_type: 'SIMPLE',
    bets: [],
    total_stake: 5,
  },
};

const slice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    setBetSlipType: (state, action: PayloadAction<'SIMPLE' | 'MULTIPLE'>) => {
      state.betslip.bet_type = action.payload;
      if (state.betslip.bet_type === 'SIMPLE') {
        state.betslip.total_stake = state.betslip.bets.reduce((sum, bet) => sum + bet.stake, 0);
      }
    },
    addBetToBetSlip: (state, action: PayloadAction<any>) => {
      state.betslip.bets.push(action.payload);
      if (state.betslip.bet_type === 'SIMPLE') {
        state.betslip.total_stake += action.payload.stake;
      }
    },
    removeBetFromBetSlip: (state, action: PayloadAction<string>) => {
      const betIndex = state.betslip.bets.findIndex((bet) => bet.id === action.payload);
      if (betIndex !== -1) {
        const [removedBet] = state.betslip.bets.splice(betIndex, 1);
        if (state.betslip.bet_type === 'SIMPLE') {
          state.betslip.total_stake -= removedBet.stake;
        }
      }
    },
    clearBetSlip: (state) => {
      state.betslip.bets = [];
      state.betslip.total_stake = 5;
    },
    updateBetSlip: (state, action: PayloadAction<any>) => {
      state.betslip = action.payload;
    },
    updateBetStake: (state, action: PayloadAction<{ id: string; stake: number }>) => {
      const bet = state.betslip.bets.find((betx) => betx.id === action.payload.id);
      if (bet) {
        if (state.betslip.bet_type === 'SIMPLE') {
          state.betslip.total_stake += action.payload.stake - bet.stake;
        }
        bet.stake = action.payload.stake;
      }
    },
    updateTotalStake: (state, action: PayloadAction<number>) => {
      if (state.betslip.bet_type !== 'SIMPLE') {
        state.betslip.total_stake = action.payload;
      }
    },
    updateOddBetToBetSlip: (state, action: PayloadAction<any>) => {
      const { new_odd } = action.payload;
      if (new_odd) {
        state.betslip.bets = state.betslip.bets.map((bet: any) =>
          new_odd?.values.some(
            (value: any) =>
              bet?.id?.split(':')[0] === `${new_odd?.id}` && bet?.value === value.value
          )
            ? { ...bet, odds: new_odd?.values.find((value: any) => bet.value === value.value).odd }
            : bet
        );
      }
    },
    updateOddBetToBetSlipLive: (state, action: PayloadAction<any>) => {
      const { new_odd } = action.payload;
      if (new_odd) {
        state.betslip.bets = state.betslip.bets.map((bet: any) =>
          new_odd.values.some(
            (value: any) =>
              bet?.id?.split(':')[0] === `${new_odd?.id}` && bet?.value === value.value
          )
            ? {
                ...bet,
                odds: new_odd?.values.find((value: any) => bet.value === value.value).odd,
                main: new_odd?.values.find((value: any) => bet.value === value.value).main,
                suspended: new_odd?.values.find((value: any) => bet.value === value.value)
                  .suspended,
              }
            : bet
        );
      }
    },
  },
});

export const {
  setBetSlipType,
  addBetToBetSlip,
  removeBetFromBetSlip,
  clearBetSlip,
  updateBetSlip,
  updateBetStake,
  updateTotalStake,
  updateOddBetToBetSlip,
  updateOddBetToBetSlipLive,
} = slice.actions;

export default slice.reducer;

export const selectBetSlip = (state: { betslip: { betslip: any } }) => state.betslip.betslip;
export const selectBetSlipType = (state: { betslip: { betslip: { bet_type: any } } }) =>
  state.betslip.betslip.bet_type;
export const selectBetSlipBets = (state: { betslip: { betslip: { bets: any } } }) =>
  state.betslip.betslip.bets;
export const selectTotalStake = (state: { betslip: { betslip: { total_stake: any } } }) =>
  state.betslip.betslip.total_stake;

export const postBet = async (data: any) => {
  const response = await axios.post(`/api/v1/client/create-ticket`, data);
  return response.data;
};

export const createBetcode = async (data: any) => {
  const response = await axios.post(`/api/v1/sport/betcode/`, data);
  return response.data;
};

export const getBetcode = async (keyId: any) => {
  const response = await axios.get(`/api/v1/sport/betcode/${keyId}/`);
  return response.data;
};
