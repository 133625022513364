import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.vendor.root,
  },
  {
    title: 'Mes Tickets',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.vendor.ticket,
  },
  {
    title: 'Rapports',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: paths.vendor.repport,
  },
];
