import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { SplashScreen } from 'src/components/loading-screen';
import { useSnackbar } from 'src/components/snackbar';
import { IS_VENDOR_PANEL } from 'src/config-global';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const { authenticated, user } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.sport.root;

  const check = useCallback(() => {
    if (!IS_VENDOR_PANEL) {
      router.replace(returnTo);
    }
    if (!authenticated) {
      router.replace(paths.vendor.login);
      enqueueSnackbar(t('login_required'), { variant: 'error' });
    } else if (authenticated && !user?.is_verified) {
      router.replace(paths.auth.jwt.verify);
    } else {
      setChecked(true);
    }
  }, [authenticated, router, user?.is_verified, returnTo, enqueueSnackbar, t]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
