// slices/match.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fDateOnly } from 'src/utils/format-time';
import axios from '../../utils/axios';
import { AppThunk } from '../store.types';

interface MatchState {
  isLoading: boolean;
  isLoadingRep: boolean;
  error: string | null;
  tickets: any;
  repports: any;
}

const initialState: MatchState = {
  isLoading: false,
  isLoadingRep: false,
  error: null,
  tickets: null,
  repports: null,
};

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTicketsSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.tickets = action.payload;
    },
    getRepportsSuccess(state, action: PayloadAction<any[]>) {
      state.isLoadingRep = false;
      state.repports = action.payload;
    },
    updateTicketPaySuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      const ref_code = action.payload;
      const ticketIndex = state.tickets?.data?.findIndex(
        (ticket: any) => ticket.ref_code === ref_code
      );
      if (ticketIndex !== -1) {
        state.tickets.data[ticketIndex].paid = 'PAID';
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  getTicketsSuccess,
  getRepportsSuccess,
  updateTicketPaySuccess,
} = slice.actions;

export default slice.reducer;

export const getTickets =
  (start_date: any, end_date: any, ref_code: string, page: number, page_size: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/api/v1/vendor/tickets`, {
        params: {
          start_date: fDateOnly(start_date, 'yyyy-MM-dd'),
          end_date: fDateOnly(end_date, 'yyyy-MM-dd'),
          ref_code,
          page,
          page_size,
        },
      });
      dispatch(getTicketsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const getTicket = async (keyId: any) => {
  const response = await axios.get(`/api/v1/vendor/ticket-id/${keyId}/`);
  return response.data;
};

export const getRepports =
  (start_date: any, end_date: any): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/api/v1/vendor/repports`, {
        params: {
          start_date: fDateOnly(start_date, 'yyyy-MM-dd'),
          end_date: fDateOnly(end_date, 'yyyy-MM-dd'),
        },
      });
      dispatch(getRepportsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const payTicket = async (keyId: any) => {
  const response = await axios.post(`/api/v1/vendor/pay-ticket/${keyId}/`);
  return response.data;
};
