import { useMemo, useEffect, useReducer, useCallback } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import { store } from 'src/redux/store';
import { getWallet } from 'src/redux/slices/wallet';

import { setSession } from './utils';
import { AuthContext } from './auth-context';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.UPDATE]: { user: any };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return { loading: false, user: action.payload.user };
    case Types.LOGIN:
      return { ...state, user: action.payload.user };
    case Types.REGISTER:
      return { ...state, user: action.payload.user };
    case Types.LOGOUT:
      return { ...state, user: null };
    case Types.UPDATE:
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        setSession(accessToken);

        const res = await axios.get(endpoints.auth.me);

        const user = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
        store.dispatch(getWallet());
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        setSession(null);
        dispatch({
          type: Types.LOGOUT,
        });
      }
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (data: any) => {
    const res = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = res.data;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
    store.dispatch(getWallet());
  }, []);

  // LOGIN VENDOR
  const login_vendor = useCallback(async (data: any) => {
    const res = await axios.post(endpoints.auth.login_vendor, data);

    const { accessToken, user } = res.data;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
    store.dispatch(getWallet());
  }, []);

  // REGISTER
  const register = useCallback(async (data: any) => {
    const res = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = res.data;

    setSession(accessToken);

    dispatch({
      type: Types.REGISTER,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
    store.dispatch(getWallet());
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const update = useCallback(async (data: any) => {
    try {
      const res = await axios.put(endpoints.auth.update, data);
      const { user } = res.data;
      dispatch({
        type: Types.UPDATE,
        payload: { user },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      login_vendor,
      register,
      logout,
      update,
    }),
    [login, login_vendor, logout, register, update, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
