import { useMemo, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useTranslate } from 'src/locales';
import { getSports } from 'src/redux/slices/generale';
import { useSelector, useDispatch } from 'src/redux/store';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';

interface SportModalProps {
  open: boolean;
  handleClose: () => void;
  handleOpenNav: () => void;
}

const SportModal = ({ open, handleClose, handleOpenNav }: SportModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const theme = useTheme();
  const { sports } = useSelector((state) => state.generale);
  const navigate = useNavigate();

  const fetchSports = useCallback(() => {
    dispatch(getSports());
  }, [dispatch]);

  useEffect(() => {
    fetchSports();
  }, [fetchSports]);

  const sports_data = useMemo(
    () =>
      sports.map((sport: { name: string; id: any }) => ({
        title: sport.name,
        path: `/sports/${sport.id}/`,
        icon: (
          <Iconify icon={`solar:${sport.name.toLowerCase()}-bold-duotone`} width={40} height={40} />
        ),
      })),
    [sports]
  );

  const handleSportClick = (path: string) => {
    navigate(path);
    handleClose();
    setTimeout(() => {
      handleOpenNav();
    }, 2000);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          position: 'fixed',
          bottom: 0,
          margin: 0,
          width: '100%',
          borderRadius: '16px 16px 0 0',
          maxHeight: '50%',
          backgroundColor: theme.palette.primary.darker,
          color: '#fff',
        },
      }}
    >
      <Box
        sx={{
          px: 3,
          pt: 1,
          paddingBottom: 0,
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t('layout.sport')}</Typography>
          <IconButton onClick={handleClose} sx={{ color: '#fff' }}>
            <Iconify icon="mdi:close" width={30} height={30} />
          </IconButton>
        </Grid>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          padding: 3,
        }}
      >
        <Grid container spacing={3}>
          {sports_data.map((sport, index) => (
            <Grid
              item
              xs={4}
              sm={3}
              key={index}
              onClick={() => handleSportClick(sport.path)}
              sx={{ textAlign: 'center', cursor: 'none' }}
            >
              {sport.icon}
              <Typography variant="body2" sx={{ mt: 0.2 }}>
                {sport.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default SportModal;
