import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector } from 'src/redux/store';

import NavSectionVertical from './vertical';

// Configuration des éléments de navigation
const NAV_ITEMS = (title: any, sportId: any, countries: any[], leagues: any[]) => [
  {
    subheader: title,
    items: countries.map((country: { name: any; id: any; flag: string | undefined }) => ({
      title: country.name,
      path: `/sports/${sportId}/${country.id}`,
      icon: <img src={country.flag} alt="icon" style={{ width: 24 }} />,
      children: leagues
        .filter((league: { country: any; sport: any }) => league.country === country.id)
        .map((league: { name: any; logo: string | undefined; id: any }) => ({
          title: league.name,
          icon: <img src={league.logo} alt="icon" style={{ width: 24 }} />,
          path: `/sports/${sportId}/${country.id}/${league.id}`,
        })),
    })),
  },
];

// Configuration du style de navigation
const config = {
  gap: 4,
  icon: 24,
  currentRole: 'admin',
  rootItemHeight: 44,
  subItemHeight: 36,
  padding: '4px 8px 4px 12px',
  radius: 8,
  hiddenLabel: false,
  hiddenSubheader: false,
};
interface Props {
  title?: string;
  load_more_txt?: string;
}
const AppSidebar = ({ title, load_more_txt, ...other }: Props) => {
  const { countries, leagues } = useSelector((state) => state.generale);

  const { sportId } = useParams();

  const navItems = useMemo(
    () => NAV_ITEMS(title, sportId || 1, countries, leagues),
    [title, sportId, countries, leagues]
  );

  return (
    <NavSectionVertical
      {...other}
      load_more_txt={load_more_txt}
      data={navItems}
      slotProps={{
        gap: config.gap,
        currentRole: config.currentRole,
        rootItem: {
          padding: config.padding,
          minHeight: config.rootItemHeight,
          borderRadius: `${config.radius}px`,
          '& .icon, .sub-icon': {
            width: config.icon,
            height: config.icon,
            ...(!config.icon && { display: 'none' }),
          },
          ...(config.hiddenLabel && {
            '& .label, .caption': {
              display: 'none',
            },
          }),
        },
        subItem: {
          padding: config.padding,
          minHeight: config.subItemHeight,
          borderRadius: `${config.radius}px`,
          '& .icon, .sub-icon': {
            width: config.icon,
            height: config.icon,
            ...(!config.icon && { display: 'none' }),
          },
          ...(config.hiddenLabel && {
            '& .label, .caption': {
              display: 'none',
            },
          }),
        },
        subheader: {
          ...(config.hiddenSubheader && {
            display: 'none',
          }),
        },
      }}
    />
  );
};

export default AppSidebar;
