import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';

import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';
import { useMockedUser } from 'src/hooks/use-mocked-user';

import { useTranslate } from 'src/locales';

import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';

import AppSidebar from 'src/sections/overview/sport/app-sidebar';
import AppGames from 'src/sections/overview/casino/app-game-list';
import AppTopCompetition from 'src/sections/overview/sport/app-top-competition';

import { NAV } from '../config-layout';
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();
  const { t } = useTranslate();

  const pathname = usePathname();

  const isOnCasinoPage = pathname === paths.casino.root || pathname === `${paths.casino.root}/`;

  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      {isOnCasinoPage ? (
        <AppGames title="Jeux" sx={{ py: 3 }} />
      ) : (
        <>
          <AppTopCompetition title={t('layout.top_competition')} sx={{ py: 3 }} />
          <AppSidebar title={t('layout.top_country')} />
        </>
      )}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
