import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { VendorGuard, VendorGuestGuard } from 'src/auth/guard';
import MainLayout from 'src/layouts/vendor';

import { LoadingScreen } from 'src/components/loading-screen';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// ----------------------------------------------------------------------

// VENDOR
const IndexPage = lazy(() => import('src/pages/vendor/app'));
const LoginPage = lazy(() => import('src/pages/auth/vendor/login'));
const GamePage = lazy(() => import('src/pages/dashboard/casino'));
const TicketListPage = lazy(() => import('src/pages/vendor/ticket/list'));
const RepportPage = lazy(() => import('src/pages/vendor/repport/list'));
// ----------------------------------------------------------------------

export const vendorRoutes = [
  {
    path: 'vendor',
    element: (
      <VendorGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </VendorGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'tickets',
        element: <TicketListPage />,
      },
      {
        path: 'repports',
        element: <RepportPage />,
      },
      {
        path: 'game/:gameId',
        element: <GamePage />,
      },
    ],
  },
  {
    path: 'vendor/login',
    element: (
      <VendorGuestGuard>
        <AuthModernCompactLayout>
          <LoginPage />
        </AuthModernCompactLayout>
      </VendorGuestGuard>
    ),
  },
];
