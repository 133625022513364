import { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useDispatch } from 'src/redux/store';
import { getWallet, postConfirmDeposit } from 'src/redux/slices/wallet';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  paymentUrl: string;
};

export default function DepositForm({ open, onClose, paymentUrl }: Props) {
  const dispatch = useDispatch();
  const handleConfimDeposit = async (transactionId: string) => {
    try {
      await postConfirmDeposit({ transactionId });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (paymentUrl === '') return () => {};

    const newWindow = window.open(paymentUrl, '_blank');

    const handleMessage = async (event: MessageEvent) => {
      if (event.origin !== new URL(paymentUrl).origin) return;

      const { transactionId } = event.data;
      if (transactionId) {
        await handleConfimDeposit(transactionId);
        await dispatch(getWallet());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      if (newWindow) {
        newWindow.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentUrl]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Deposit</DialogTitle>

      <DialogContent dividers>
        <p>
          The payment window has been opened in a new tab. Please complete the payment and return to
          this window.
        </p>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
