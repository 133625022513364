import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
// export const IS_VENDOR_PANEL = true;
export const IS_VENDOR_PANEL = (() => {
  const hostname = window.location.hostname;

  return hostname.startsWith('cash.');
})();
// export const HOST_API = process.env.REACT_APP_HOST_API;
// export const WSS_HOST_API = process.env.REACT_APP_WSS_HOST_API;
export const HOST_API = (() => {
  const hostname = window.location.hostname; // Use 'hostname' instead of 'host' to avoid port numbers.

  const parts = hostname.split('.');

  if (parts.length > 2) {
    return `${window.location.protocol}//${parts.slice(-2).join('.')}`;
  }

  return window.location.origin;
})();
const getWebSocketURL = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const hostname = window.location.hostname; // Use 'hostname' to exclude port numbers

  const parts = hostname.split('.');

  if (parts.length > 2) {
    return `${protocol}//${parts.slice(-2).join('.')}`;
  }

  return `${protocol}//${hostname}`;
};

export const WSS_HOST_API = getWebSocketURL();
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const SUPABASE_API = {
  url: process.env.REACT_APP_SUPABASE_URL,
  key: process.env.REACT_APP_SUPABASE_ANON_KEY,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.sport.root; // as '/sport'
