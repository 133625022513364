import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import BetSlip from 'src/sections/overview/sport/app-betslip/BetSlip';

interface BetslipModalProps {
  open: boolean;
  handleClose: () => void;
}

const BetslipModal: React.FC<BetslipModalProps> = ({ open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiDialog-paper': {
        position: 'fixed',
        bottom: 0,
        margin: 0,
        width: 1,
        borderRadius: 2,
        maxHeight: 1,
      },
    }}
  >
    <Box
      sx={{
        overflowY: 'auto',
        height: 1,
      }}
    >
      <BetSlip isScroll={false} handleClose={handleClose} />
    </Box>
  </Dialog>
);

export default BetslipModal;
