import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import React, { useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { fCurrency } from 'src/utils/format-number';

import { bgBlur } from 'src/theme/css';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useSelector, useDispatch } from 'src/redux/store';
import { getWallet, postDeposit, postWithdrawal } from 'src/redux/slices/wallet';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useSnackbar } from 'src/components/snackbar';
import { NavSectionGame } from 'src/components/nav-section';
import { useSettingsContext } from 'src/components/settings';
import FormProvider, { RHFTextField, RHFPhoneField } from 'src/components/hook-form';

import { Deposit, Withdraw, WebPayment } from 'src/sections/wallet';

import { NAV, HEADER } from '../config-layout';
import { useNavGame } from './config-navigation';
import AccountPopover from '../common/account-popover';
import SettingsButton from '../common/settings-button';
import LanguagePopover from '../common/language-popover';
// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { t } = useTranslate();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');
  const smDown = useResponsive('down', 'sm');
  const navigate = useNavigate();
  const { user, authenticated, login } = useAuthContext();
  const navData = useNavGame();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { wallet } = useSelector((state: { wallet: any }) => state.wallet);

  const depositForm = useBoolean();
  const withdrawForm = useBoolean();
  const webPayment = useBoolean();
  const [paymentUrl, setPaymentUrl] = useState('');
  const handleDeposit = useCallback(async (deposit: any) => {
    try {
      const result = await postDeposit({ method: deposit.paymentMethod, amount: deposit.amount });
      if (result.redirect_url) {
        setPaymentUrl(result.redirect_url);
        webPayment.onTrue();
      } else {
        alert('Failed to initiate payment');
        setPaymentUrl('');
      }
    } catch (error) {
      console.error('Error initiating payment:', error?.response?.data);
      const errorMessage =
        typeof error?.response?.data === 'string'
          ? error?.response?.data
          : error?.response?.data?.non_field_errors[0];
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      setPaymentUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWithdraw = useCallback(async (deposit: any) => {
    try {
      await postWithdrawal({
        method: deposit.paymentMethod,
        amount: deposit.amount,
        pin: deposit.pin,
        phoneNumber: deposit.phoneNumber,
      });
      await dispatch(getWallet());
      enqueueSnackbar(t('wallet.withdraw_success'));
    } catch (error) {
      console.error('Error initiating withdraw:', error);
      const errorMessage =
        typeof error?.response?.data === 'string'
          ? error?.response?.data
          : error?.response?.data?.non_field_errors[0];
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      setPaymentUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginSchema = Yup.object().shape({
    // phone: Yup.string().required(t('auth.phoneRequired')),
    password: Yup.string().required(t('auth.passwordRequired')),
  });

  const password = useBoolean();

  const defaultValues = {
    phone: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    if (!data?.phone || data?.phone === '') {
      enqueueSnackbar(t('auth.phoneRequired'), {
        variant: 'error',
      });
      return;
    }
    try {
      await login?.(data);
    } catch (error) {
      const errorMessage =
        typeof error?.response?.data?.error === 'string'
          ? error?.response?.data?.error
          : error?.response?.data?.join(', ');
      console.error(error);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  });

  const handleSignup = () => {
    navigate(paths.auth.jwt.register);
  };

  const handleLogin = () => {
    navigate(paths.auth.jwt.login);
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/static/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}
      {lgUp && isNavHorizontal && (
        <NavSectionGame
          data={navData}
          slotProps={{
            currentRole: user?.role,
          }}
          sx={{
            ...theme.mixins.toolbar,
            ml: 2.5,
          }}
        />
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ gap: '5px' }}
      >
        {authenticated ? (
          <>
            {!smDown && (
              <>
                <Button
                  onClick={depositForm.onTrue}
                  variant="contained"
                  color="primary"
                  startIcon={<Iconify width={20} icon="majesticons:money-plus-line" />}
                >
                  {t('wallet.deposit')}
                </Button>
                <Button
                  onClick={withdrawForm.onTrue}
                  variant="outlined"
                  color="secondary"
                  startIcon={<Iconify width={20} icon="majesticons:money-minus-line" />}
                >
                  {t('wallet.withdraw')}
                </Button>
              </>
            )}
            <Button
              component={RouterLink}
              href={paths.dashboard.user.account_menu('transaction')}
              variant="outlined"
              color="primary"
              startIcon={<Iconify width={24} icon="solar:wallet-money-bold-duotone" />}
            >
              {fCurrency(wallet?.balance)}
            </Button>
            <Deposit
              open={depositForm.value}
              onClose={depositForm.onFalse}
              onCreate={handleDeposit}
            />
            <Withdraw
              open={withdrawForm.value}
              onClose={withdrawForm.onFalse}
              onCreate={handleWithdraw}
            />
            <WebPayment
              paymentUrl={paymentUrl}
              open={webPayment.value}
              onClose={webPayment.onFalse}
            />
            <LanguagePopover />
            {/* <SettingsButton /> */}
            <AccountPopover />
          </>
        ) : (
          <>
            {!smDown ? (
              <>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <RHFPhoneField
                      name="phone"
                      variant="outlined"
                      size="small"
                      label={t('layout.phone')}
                    />
                    <RHFTextField
                      name="password"
                      label={t('layout.password')}
                      variant="outlined"
                      size="small"
                      type={password.value ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                              <Iconify
                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <LoadingButton
                      color="primary"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{ px: 4 }}
                    >
                      {t('layout.login')}
                    </LoadingButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSignup}
                      sx={{ px: 4 }}
                    >
                      {t('layout.register')}
                    </Button>
                  </Stack>
                </FormProvider>
                <LanguagePopover />
                {/* <SettingsButton /> */}
              </>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <Button variant="contained" color="primary" onClick={handleLogin}>
                  {t('layout.login')}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSignup}>
                  {t('layout.register')}
                </Button>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          height: HEADER.H_DESKTOP,
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: '#FFFFFF',
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
        ...(!lgUp && {
          px: 1.3,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: 0,
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
