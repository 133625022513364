import { useMemo, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useResponsive } from 'src/hooks/use-responsive';

import { hideScroll } from 'src/theme/css';
import { useTranslate } from 'src/locales';
import { getSports } from 'src/redux/slices/generale';
import { useSelector, useDispatch } from 'src/redux/store';

import Iconify from 'src/components/iconify';
import { NavSectionSport } from 'src/components/nav-section';

import { NAV, HEADER } from '../config-layout';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function NavSport() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { sports } = useSelector((state) => state.generale);

  const fetchSports = useCallback(() => {
    dispatch(getSports());
  }, [dispatch]);

  useEffect(() => {
    fetchSports();
  }, [fetchSports]);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('layout.sport'),
        items: sports.map((sport: { name: string; id: any }) => ({
          title: sport.name,
          path: `/sports/${sport.id}`,
          icon: <Iconify icon={`solar:${sport.name.toLowerCase()}-bold-duotone`} width={24} />,
        })),
      },
    ],
    [t, sports]
  );

  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <Stack
        sx={{
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          backgroundColor: (theme) => theme.palette.primary.darker,
          ...hideScroll.x,
          py: `${HEADER.H_MOBILE + SPACING}px`,
          ...(lgUp && {
            py: `${HEADER.H_DESKTOP + SPACING}px`,
          }),
        }}
      >
        <NavSectionSport data={data} />
      </Stack>
    </Box>
  );
}
