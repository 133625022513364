export const getBetValue = (value: any, handicap: any) => {
  if (handicap === null || handicap === undefined) {
    return value;
  }
  return `${value} ${handicap}`;
};

export function getBetFullName(t: any, value: any, handicap: any, type_bet?: any) {
  const stringValue = String(value);
  if (type_bet && type_bet.includes('Goal Scorer')) {
    return `${String(value)} ${handicap?.length > 0 ? handicap : ''}`;
  }
  // console.log('avant ', stringValue);
  const exactScorePatternColon = /^\d+:\d+$/;
  const exactScorePatternDash = /^\d+-\d+$/;
  const numberOnlyPattern = /^[+-]?\d+(\.\d+)?$/;

  if (exactScorePatternColon.test(stringValue)) {
    const [homeScore, awayScore] = stringValue.split(':');
    const translation = `${homeScore} - ${awayScore}`;
    return translation;
  }

  if (exactScorePatternDash.test(stringValue)) {
    const [homeScore, awayScore] = stringValue.split('-');
    const translation = `${homeScore} - ${awayScore}`;
    return translation;
  }
  if (numberOnlyPattern.test(stringValue)) {
    // If the string is a number only, return it directly
    return stringValue;
  }
  const translation = t(`bet.values.${value}`);
  if (handicap === null || handicap === undefined || handicap === '') {
    return translation;
  }
  return `${translation} ${handicap}`;
}

interface Bet {
  value: string | number;
  main?: boolean;
  [key: string]: any; // Allows for additional properties
}

export const updateMainField = (bets: Bet[]): Bet[] => {
  const valueCount: { [key: string]: number } = {};

  // Count occurrences of each bet value
  bets.forEach((bet: Bet) => {
    valueCount[bet.value] = (valueCount[bet.value] || 0) + 1;
  });

  // Update the main field based on the count
  return bets.map((bet: Bet) => {
    if (valueCount[bet.value] > 1) {
      // Set the first occurrence as main, others as not main
      const isFirstOccurrence = !bets.some((b: Bet) => b.value === bet.value && b.main);
      return { ...bet, main: isFirstOccurrence };
    }
    return { ...bet, main: false }; // Unique values set to false
  });
};
