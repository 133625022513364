// slices/generale.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { AppThunk } from '../store.types';

interface GeneraleState {
  isLoading: boolean;
  error: string | null;
  sports: any[];
  countries: any[];
  leagues: any[];
  promotes: any[];
  games: any[];
  game_detail: any | null;
}

const initialState: GeneraleState = {
  isLoading: false,
  error: null,
  sports: [],
  countries: [],
  leagues: [],
  promotes: [],
  games: [],
  game_detail: null,
};

const slice = createSlice({
  name: 'generale',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getSportsSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.sports = action.payload;
    },
    getCountriesSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.countries = action.payload;
    },
    getLeaguesSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.leagues = action.payload;
    },
    getPromotesSuccess(state, action) {
      state.isLoading = false;
      state.promotes = action.payload;
    },
    getGamesSuccess(state, action) {
      state.isLoading = false;
      state.games = action.payload;
    },
    getGameSuccess(state, action) {
      state.isLoading = false;
      state.game_detail = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  getSportsSuccess,
  getCountriesSuccess,
  getLeaguesSuccess,
  getPromotesSuccess,
  getGamesSuccess,
  getGameSuccess,
} = slice.actions;

export default slice.reducer;

export const getSports = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/api/v1/sport/sports`);
    dispatch(getSportsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getCountries =
  (sportId: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      const queryParams = sportId ? `?sport=${sportId}` : '';
      const response = await axios.get(`/api/v1/sport/country${queryParams}`);
      dispatch(getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const getLeagues =
  (sportId: string | undefined, countryId: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      const queryParams = [];
      if (sportId) queryParams.push(`sport=${sportId}`);
      if (countryId && countryId !== '-1') queryParams.push(`country=${countryId}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      const response = await axios.get(`/api/v1/sport/leagues${queryString}`);
      dispatch(getLeaguesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const getPromotes = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('/api/v1/auth/promotes');
    dispatch(getPromotesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getGames = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('/api/v1/auth/games');
    dispatch(getGamesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getGame = async (keyId: any) => {
  const response = await axios.get(`/api/v1/auth/game/${keyId}`);
  return response.data;
};
