// slices/match.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { AppThunk } from '../store.types';

interface MatchState {
  isLoading: boolean;
  error: string | null;
  tickets: any[];
  tickets_casino: any[];
}

const initialState: MatchState = {
  isLoading: false,
  error: null,
  tickets: [],
  tickets_casino: [],
};

const slice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTicketsSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.tickets = action.payload;
    },
    getTicketsCasinoSuccess(state, action: PayloadAction<any[]>) {
      state.isLoading = false;
      state.tickets_casino = action.payload;
    },
  },
});

export const { startLoading, hasError, getTicketsSuccess, getTicketsCasinoSuccess } = slice.actions;

export default slice.reducer;

export const getTickets = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/api/v1/client/tickets`);
    dispatch(getTicketsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getTicketsCasino = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/api/v1/client/tickets-casino`);
    dispatch(getTicketsCasinoSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const getTicket = async (keyId: any) => {
  const response = await axios.get(`/api/v1/client/ticket-id/${keyId}/`);
  return response.data;
};
