import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Stack,
  Badge,
  Button,
  styled,
  Switch,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { paths } from 'src/routes/paths';

import { getBetFullName } from 'src/utils/bet';
import { fCurrency } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { getWallet } from 'src/redux/slices/wallet';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  postBet,
  createBetcode,
  clearBetSlip,
  selectBetSlip,
  setBetSlipType,
  updateBetStake,
  updateTotalStake,
  selectTotalStake,
  selectBetSlipType,
  selectBetSlipBets,
  removeBetFromBetSlip,
} from 'src/redux/slices/betslip';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import ShareBetCode from './ShareBetCode';

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.darker,
  borderRadius: 13,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '100%', // Added to make it stretch
  maxWidth: '100%', // Ensures it doesn't exceed 100% width
}));

const TitleBox = styled(Box)(({ theme }) => ({
  alignSelf: 'stretch',
  height: '57px',
  padding: theme.spacing(1.25),
  display: 'flex',
  justifyContent: 'space-between', // Changed from space-around to space-between
  alignItems: 'center',
  width: '100%',
}));

const SelectionBox = styled(Box)(() => ({
  alignSelf: 'stretch',
  height: '32px',
  display: 'flex',
  backgroundColor: 'white',
  width: '100%',
}));

const SelectionButton = styled(Button)<{ selected: boolean }>(({ theme, selected }) => ({
  flex: 1,
  padding: theme.spacing(0.5),
  ...(selected && {
    background: `linear-gradient(274.4deg, ${theme.palette.primary.light} 0%, ${theme.palette.secondary.light} 100%)`,
    color: 'white',
  }),
  '&:hover': {
    background: `linear-gradient(274.4deg, ${theme.palette.secondary.light} 0%, ${theme.palette.primary.light} 100%)`,
    color: 'white',
  },
  borderRadius: 0,
}));

const BetFooter = styled(Box)(() => ({
  color: 'white',
  fontWeight: '600',
  padding: 12,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const FooterButton = styled(LoadingButton)(({ theme }) => ({
  height: 36,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 13,
  color: 'white',
  fontWeight: '600',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Content = styled(Box, { shouldForwardProp: (prop) => prop !== 'isScroll' })<{
  isScroll: boolean;
}>(({ isScroll }) => ({
  padding: 8,
  backgroundColor: '#E9EDF0',
  width: '100%', // Added to make it stretch
  ...(isScroll && {
    maxHeight: 530,
    overflowY: 'auto',
  }),
}));

const BetCard = styled(Box)({
  borderRadius: 13,
  display: 'flex',
  flexDirection: 'column',
});

const BetCardItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const BetCardEmpty = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
}));

const HeaderBetItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const MatchInfo = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: '500',
  color: '#000000',
  marginBottom: theme.spacing(1),
}));

const StakeButton = styled(Button)<{ active: boolean }>(({ theme, active }) => ({
  minWidth: '35px',
  height: '30px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: active ? theme.palette.primary.main : theme.palette.primary.darker,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  fontSize: 14,
}));

const BetCardItemLockedContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  position: 'relative',
}));

const BetCardItemLockedOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  fontSize: 20,
  borderRadius: theme.shape.borderRadius,
  pointerEvents: 'none',
}));

type Bet = {
  id: string;
  datetime: string;
  matchId: number;
  homeTeam: string;
  awayTeam: string;
  league: string;
  country: string;
  type_bet: string;
  value: string;
  handicap: string;
  odds: string;
  stake: number;
  is_live: boolean;
  main: boolean;
  suspended: boolean;
};

const BetSlip: React.FC<{ isScroll?: boolean; handleClose?: any }> = ({
  isScroll = true,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const betSlipType = useSelector(selectBetSlipType);
  const betSlipBets = useSelector(selectBetSlipBets);
  const totalStake = useSelector(selectTotalStake);
  const { wallet } = useSelector((state) => state.wallet);
  const [allowOddChange, setAllowOddChange] = useState(false);
  const hasPastEvent =
    betSlipBets.some(
      (bet: { datetime: string | number | Date; is_live: boolean }) =>
        !bet.is_live && new Date() > new Date(bet.datetime)
    ) || betSlipBets.length === 0;

  const handleStakeChange = (betId: string, stake: number) => {
    if (stake > wallet?.balance) {
      alert('Insufficient balance');
      return;
    }
    dispatch(updateBetStake({ id: betId, stake }));
  };

  const handleTotalStakeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stake = Math.max(0, event.target.value === '' ? 0 : parseFloat(event.target.value));
    const formattedValue = stake.toString(); // Convert the number back to a string
    event.target.value = formattedValue; // Update the input value with the formatted string
    if (stake > wallet?.balance) {
      alert('Insufficient balance');
      return;
    }
    dispatch(updateTotalStake(stake));
  };

  const handleRemoveBet = (betId: string) => {
    dispatch(removeBetFromBetSlip(betId));
  };

  const handleClearBetSlip = () => {
    dispatch(clearBetSlip());
  };

  const totalOdds = betSlipBets.reduce((acc: number, bet: Bet) => acc * parseFloat(bet.odds), 1);

  const potentialWinnings =
    betSlipType === 'SIMPLE'
      ? betSlipBets?.reduce((acc: number, bet: Bet) => acc + bet.stake * parseFloat(bet.odds), 0)
      : totalStake * totalOdds;

  const totalStakes =
    betSlipType === 'SIMPLE'
      ? betSlipBets?.reduce((acc: number, nbet: Bet) => acc + (nbet.stake || 0), 0)
      : totalStake;

  const handleBetSlipTypeChange = (type: 'SIMPLE' | 'MULTIPLE') => {
    const matchIdCount = betSlipBets.reduce((acc: { [key: number]: number }, bet: Bet) => {
      acc[bet.matchId] = (acc[bet.matchId] || 0) + 1;
      return acc;
    }, {});

    const hasDuplicateMatchId = Object?.values(matchIdCount).some((count) => (count as number) > 1);

    if (type === 'MULTIPLE' && hasDuplicateMatchId) {
      return;
    }
    if (type === 'MULTIPLE' && betSlipBets.length <= 1) {
      return;
    }

    dispatch(setBetSlipType(type));
  };

  const betSlip = useSelector(selectBetSlip);

  const { authenticated } = useAuthContext();

  const handleSubmit = async () => {
    const hasPastEventBtn =
      betSlipBets.some(
        (bet: { datetime: string | number | Date; is_live: boolean }) =>
          !bet.is_live && new Date() > new Date(bet.datetime)
      ) || betSlipBets.length === 0;
    if (hasPastEventBtn) {
      enqueueSnackbar(
        'There are events that have already started. Please remove them before placing the bet.',
        { variant: 'error' }
      );
      return;
    }

    if (!authenticated) {
      enqueueSnackbar('Vous devez être connecté avant de placer un pari', {
        variant: 'error',
      });
      navigate(paths.auth.jwt.login);
      return;
    }

    try {
      await postBet(betSlip);
      handleClearBetSlip();
      enqueueSnackbar('Votre pari a été fait.Bonne chance!');
      dispatch(getWallet());
    } catch (error) {
      const errorMessage =
        typeof error?.response?.data?.error === 'string'
          ? error?.response?.data?.error
          : error?.response?.data?.join(', ');
      console.error(error);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  return (
    <Container>
      <Box sx={{ px: 0.4 }}>
        <Header
          betSlipType={betSlipType}
          betCount={betSlipBets.length}
          handleBetSlipTypeChange={handleBetSlipTypeChange}
          handleClearBetSlip={handleClearBetSlip}
          handleClose={handleClose}
          isScroll={isScroll}
        />
        {betSlipBets.length === 0 ? (
          <BetCardEmpty>
            <Typography sx={{ textAlign: 'center', py: 2 }}>{t('betslip.empty')}</Typography>
          </BetCardEmpty>
        ) : (
          <BetList
            betEvents={betSlipBets}
            handleStakeChange={handleStakeChange}
            handleRemoveBet={handleRemoveBet}
            isScroll={isScroll}
          />
        )}
        <Footer
          betSlipType={betSlipType}
          totalStakes={totalStakes}
          potentialWinnings={potentialWinnings}
          handleTotalStakeChange={handleTotalStakeChange}
          totalStake={totalStake}
          allowOddChange={allowOddChange}
          setAllowOddChange={setAllowOddChange}
          handleSubmit={handleSubmit}
          hasPastEvent={hasPastEvent}
        />
      </Box>
    </Container>
  );
};

interface HeaderProps {
  betSlipType: string;
  betCount: number;
  isScroll: boolean;
  handleClearBetSlip: () => void;
  handleClose: () => void;
  handleBetSlipTypeChange: (type: 'SIMPLE' | 'MULTIPLE') => void;
}

const Header: React.FC<HeaderProps> = ({
  betSlipType,
  betCount,
  isScroll,
  handleClearBetSlip,
  handleClose,
  handleBetSlipTypeChange,
}) => {
  const { t } = useTranslate();
  return (
    <Box>
      <TitleBox>
        {!isScroll && (
          <IconButton size="small" onClick={handleClose}>
            <Iconify icon="mdi:close" width={24} height={24} color="white" />
          </IconButton>
        )}
        <Badge badgeContent={betCount} color="secondary" sx={{ mr: 2 }}>
          <Typography variant="h6" color="white">
            {t('betslip.title')}
          </Typography>
        </Badge>
        <IconButton size="small" onClick={handleClearBetSlip}>
          <Iconify icon="mdi:delete" width={24} height={24} color="white" />
        </IconButton>
      </TitleBox>
      <SelectionBox>
        <SelectionButton
          onClick={() => handleBetSlipTypeChange('SIMPLE')}
          selected={betSlipType === 'SIMPLE'}
        >
          {t('betslip.simple')}
        </SelectionButton>
        <SelectionButton
          onClick={() => handleBetSlipTypeChange('MULTIPLE')}
          selected={betSlipType === 'MULTIPLE'}
        >
          {t('betslip.multiple')}
        </SelectionButton>
      </SelectionBox>
    </Box>
  );
};

interface FooterProps {
  betSlipType: string;
  totalStakes: number;
  potentialWinnings: number;
  handleTotalStakeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalStake: number;
  allowOddChange: boolean;
  setAllowOddChange: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  hasPastEvent: boolean;
}

const Footer: React.FC<FooterProps> = ({
  betSlipType,
  totalStakes,
  potentialWinnings,
  handleTotalStakeChange,
  totalStake,
  allowOddChange,
  setAllowOddChange,
  handleSubmit,
  hasPastEvent,
}) => {
  const theme = useTheme();
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const betSlipBets = useSelector(selectBetSlipBets);
  const betSlip = useSelector(selectBetSlip);
  const totalOdds = betSlipBets.reduce((acc: number, bet: Bet) => acc * parseFloat(bet.odds), 1);
  const shareTicket = useBoolean();
  const [betcodeDt, setBetcodeDt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);

  const handleShareTicket = async () => {
    if (loadingShare) return;

    setLoadingShare(true);
    const hasPastEventBtn =
      betSlipBets.some(
        (bet: { datetime: string | number | Date; is_live: boolean }) =>
          !bet.is_live && new Date() > new Date(bet.datetime)
      ) || betSlipBets.length === 0;
    if (hasPastEventBtn) {
      enqueueSnackbar(
        'There are events that have already started. Please remove them before placing the bet.',
        { variant: 'error' }
      );
      return;
    }
    try {
      const res = await createBetcode(betSlip);
      setBetcodeDt(res);
      shareTicket.onTrue();
    } catch (error) {
      const errorMessage =
        typeof error?.response?.data?.error === 'string'
          ? error?.response?.data?.error
          : error?.response?.data?.join(', ');
      console.error(error);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    } finally {
      setLoadingShare(false);
    }
  };

  const handleSubmitWithLoading = async () => {
    if (loading) return; // Prevent further clicks if loading

    setLoading(true); // Set loading to true when the submit process starts

    try {
      await handleSubmit(); // Call the original handleSubmit function
    } finally {
      setLoading(false); // Reset loading state when the process is complete
    }
  };

  return (
    <BetFooter>
      {betSlipBets?.length > 0 && (
        <>
          {betSlipType === 'MULTIPLE' && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Stack direction="row" justifyContent="space-around" sx={{ pt: 1.5 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleTotalStakeChange({
                        target: { value: Math.max(5, totalStake - 1).toString() },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                  >
                    <Iconify
                      icon="zondicons:minus-outline"
                      width={20}
                      height={20}
                      color="#ffffff"
                    />
                  </IconButton>
                  <Box
                    sx={{
                      width: 60,
                      height: 34,
                      borderRadius: 0.8,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <TextField
                      type="number"
                      value={totalStake}
                      onChange={handleTotalStakeChange}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: '#ffffff',
                          fontSize: 12,
                          fontWeight: '600',
                        },
                      }}
                      variant="outlined"
                      size="small"
                      sx={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleTotalStakeChange({
                        target: { value: (totalStake + 1).toString() },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                  >
                    <Iconify icon="octicon:plus-circle-16" width={20} height={20} color="#ffffff" />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={1} sx={{ py: 1 }}>
                {[5, 10, 50, 100, 1000].map((amount) => (
                  <StakeButton
                    key={amount}
                    active={totalStake === amount}
                    onClick={() =>
                      handleTotalStakeChange({
                        target: { value: amount.toString() },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                  >
                    {amount}
                  </StakeButton>
                ))}
              </Stack>
            </Box>
          )}
          <Stack direction="row" alignItems="center">
            <Switch
              checked={allowOddChange}
              onChange={(event) => setAllowOddChange(event.target.checked)}
              sx={{ ml: -1.5 }}
            />
            <Typography variant="body2" color="inherit" sx={{ fontSize: 13 }}>
              {t('betslip.authorized_change_odd')}
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="body2" color="inherit">
              {t('betslip.odd_total')}
            </Typography>
            <Typography variant="body2" color="inherit">
              {parseFloat(totalOdds.toString()).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="body2" color="inherit">
              {t('betslip.mise_total')}
            </Typography>
            <Typography variant="body2" color="inherit">
              {fCurrency(totalStakes)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="body2" color="inherit">
              {t('betslip.gain_potentiel')}
            </Typography>
            <Typography variant="body2" color="inherit">
              {fCurrency(potentialWinnings)}
            </Typography>
          </Box>
        </>
      )}

      <Box sx={{ py: 2 }}>
        <FooterButton
          loading={loading}
          onClick={handleSubmitWithLoading}
          disabled={hasPastEvent || loading}
        >
          {t('betslip.create_ticket')}
        </FooterButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Stack onClick={handleShareTicket} direction="row" spacing={1} sx={{ cursor: 'pointer' }}>
          <Iconify icon="ic:sharp-share" sx={{ color: 'white' }} />
          <Typography sx={{ color: 'white', fontSize: 14, fontWeight: '600' }}>
            {t('betslip.share_betcode')}
          </Typography>
        </Stack>
      </Box>
      <ShareBetCode open={shareTicket.value} onClose={shareTicket.onFalse} betcodeDt={betcodeDt} />
    </BetFooter>
  );
};

interface BetListProps {
  betEvents: Bet[];
  isScroll: boolean;
  handleStakeChange: (betId: string, stake: number) => void;
  handleRemoveBet: (betId: string) => void;
}

const BetList: React.FC<BetListProps> = ({
  betEvents,
  handleStakeChange,
  handleRemoveBet,
  isScroll,
}) => (
  <Content isScroll={isScroll}>
    <BetCard>
      {betEvents.map((event) => (
        <BetItem
          key={event.id}
          bet={event}
          handleStakeChange={handleStakeChange}
          handleRemoveBet={handleRemoveBet}
        />
      ))}
    </BetCard>
  </Content>
);

interface BetItemProps {
  bet: Bet;
  handleStakeChange: (betId: string, stake: number) => void;
  handleRemoveBet: (betId: string) => void;
}

const BetItem: React.FC<BetItemProps> = ({ bet, handleStakeChange, handleRemoveBet }) => {
  const [selectedStake, setSelectedStake] = useState(bet.stake);
  const betSlipType = useSelector(selectBetSlipType);
  const theme = useTheme();
  const { t } = useTranslate();
  const PRIMARY_DARKER = theme.palette.primary.darker;
  const isPastEvent = new Date() > new Date(bet.datetime);

  const handleStakeClick = (amount: number) => {
    const newStake = Math.max(0, amount);
    setSelectedStake(newStake);
    handleStakeChange(bet.id, newStake);
  };

  const handleStakeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, event.target.value === '' ? 0 : parseFloat(event.target.value));
    const formattedValue = value.toString(); // Convert the number back to a string
    handleStakeClick(value); // Pass the original number value to handleStakeClick
    event.target.value = formattedValue; // Update the input value with the formatted string
  };

  const datetime_ = new Date(bet.datetime);
  const time = datetime_.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const date = datetime_.toISOString().split('T')[0]; // Get date in YYYY-MM-DD format

  if (isPastEvent && !bet?.is_live) {
    return <BetCardItemLocked bet={bet} handleRemoveBet={handleRemoveBet} />;
  }

  if (bet?.suspended) {
    return <BetCardItemLocked bet={bet} handleRemoveBet={handleRemoveBet} />;
  }

  return (
    <BetCardItem>
      <HeaderBetItem>
        <Stack>
          <MatchInfo sx={{ fontSize: 13, fontWeight: '600' }}>
            {bet.homeTeam} - {bet.awayTeam}
          </MatchInfo>
          {/* <MatchInfo>
            {bet.country} {bet.country && '-'} {bet.league}
          </MatchInfo> */}
        </Stack>
        <IconButton size="small" onClick={() => handleRemoveBet(bet.id)}>
          <Iconify icon="mdi:close" width={24} height={24} />
        </IconButton>
      </HeaderBetItem>
      <Stack>
        {/* <MatchInfo sx={{ fontSize: 14, fontWeight: '600' }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: '600' }}>
              {time}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {date}
            </Typography>
          </Stack>
        </MatchInfo> */}
        <MatchInfo sx={{ fontSize: 14, fontWeight: '600' }}>
          {`${t(`bet.type_bet.${bet.type_bet}`)} : ${getBetFullName(
            t,
            bet.value,
            bet?.handicap,
            bet.type_bet
          )}`}
        </MatchInfo>

        <Stack direction="row" spacing={1}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: 13, fontWeight: '500', color: '#000000' }}
          >
            {t('betslip.odd')}:
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: 13, fontWeight: '700', color: '#000000' }}
          >
            {bet.odds}
          </Typography>
        </Stack>
      </Stack>
      {betSlipType === 'SIMPLE' && (
        <>
          <Stack direction="row" justifyContent="space-around" sx={{ pt: 1 }}>
            <Box
              sx={{
                height: 35,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              <Typography
                sx={{ textAlign: 'right', color: 'black', fontSize: 14, fontWeight: '700' }}
              >
                {t('betslip.mise')}
              </Typography>
              <Typography
                sx={{ textAlign: 'right', color: 'black', fontSize: 12, fontWeight: '500' }}
              >
                {fCurrency(bet.stake)}
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="small"
                onClick={() => handleStakeClick(Math.max(5, selectedStake - 1))}
              >
                <Iconify
                  icon="zondicons:minus-outline"
                  width={20}
                  height={20}
                  color={PRIMARY_DARKER}
                />
              </IconButton>
              <Box
                sx={{
                  width: { xs: 90, md: 60 },
                  height: 34,
                  borderRadius: 0.8,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <TextField
                  type="number"
                  value={selectedStake}
                  onChange={handleStakeInputChange}
                  inputProps={{
                    style: { textAlign: 'center', color: 'black', fontSize: 12, fontWeight: '600' },
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ width: '100%', height: '100%' }}
                />
              </Box>
              <IconButton size="small" onClick={() => handleStakeClick(selectedStake + 1)}>
                <Iconify
                  icon="octicon:plus-circle-16"
                  width={20}
                  height={20}
                  color={PRIMARY_DARKER}
                />
              </IconButton>
            </Stack>
            <Box
              sx={{
                height: 35,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              <Typography
                sx={{ textAlign: 'right', color: 'black', fontSize: 14, fontWeight: '700' }}
              >
                {t('betslip.gain')}
              </Typography>
              <Typography
                sx={{ textAlign: 'right', color: 'black', fontSize: 12, fontWeight: '500' }}
              >
                {fCurrency(selectedStake * parseFloat(bet.odds))}
              </Typography>
            </Box>
          </Stack>

          <Stack
            spacing={1}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              pt: 1,
            }}
          >
            {[5, 10, 50, 100].map((amount) => (
              <StakeButton
                key={amount}
                active={selectedStake === amount}
                onClick={() => handleStakeClick(amount)}
              >
                {amount}
              </StakeButton>
            ))}
          </Stack>
        </>
      )}
    </BetCardItem>
  );
};

const BetCardItemLocked: React.FC<{ bet: Bet; handleRemoveBet: (betId: string) => void }> = ({
  bet,
  handleRemoveBet,
}) => {
  const { t } = useTranslate();
  const datetime_ = new Date(bet.datetime);
  const time = datetime_.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const date = datetime_.toISOString().split('T')[0]; // Get date in YYYY-MM-DD format

  return (
    <BetCardItemLockedContainer>
      <HeaderBetItem>
        <Stack>
          <MatchInfo sx={{ fontSize: 13, fontWeight: '600' }}>
            {bet.homeTeam} - {bet.awayTeam}
          </MatchInfo>
          {/* <MatchInfo>
            {bet.country} {bet.country && '-'} {bet.league}
          </MatchInfo> */}
        </Stack>
        <IconButton size="small" onClick={() => handleRemoveBet(bet.id)}>
          <Iconify icon="mdi:close" width={24} height={24} />
        </IconButton>
      </HeaderBetItem>
      <Stack>
        {/* <MatchInfo sx={{ fontSize: 14, fontWeight: '600' }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: '600' }}>
              {time}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {date}
            </Typography>
          </Stack>
        </MatchInfo> */}
        <MatchInfo sx={{ fontSize: 14, fontWeight: '600' }}>
          {`${t(`bet.type_bet.${bet.type_bet}`)} : ${getBetFullName(
            t,
            bet.value,
            bet?.handicap,
            bet.type_bet
          )}`}
        </MatchInfo>

        <Stack direction="row" spacing={1}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: 13, fontWeight: '500', color: '#000000' }}
          >
            {t('betslip.odd')}:
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: 13, fontWeight: '700', color: '#000000' }}
          >
            {bet.odds}
          </Typography>
        </Stack>
      </Stack>
      <BetCardItemLockedOverlay>
        <Iconify icon="mdi:lock" width={48} height={48} />
      </BetCardItemLockedOverlay>
    </BetCardItemLockedContainer>
  );
};
export default BetSlip;
